<template>
    <div class="process" id="work">
        <div class="process__content container">
            <div class="process__items">
                <div class="process__items-top">
                    <div class="process__items-top-title">
                        <h2 class="font-32">{{ $t("process.title") }}</h2>
                    </div>
                    <div class="process__items-top-desc">
                        <p class="font-16">{{ $t("process.description") }}</p>
                    </div>
                </div>
                <div class="process__items-cards">
                    <div class="process__items-cards-card" v-for="(item, index) in cardData" :key="index">
                        <div :style="`background-color: ` + item.color + `;`"
                            class="process__items-cards-card-line">
                            <img :src="item.icon" alt="">
                        </div>
                        <div class="process__items-cards-card-content">
                            <div class="process__items-cards-card-content-title">
                                <span class="font-19">{{index+1}}. {{item.title}}</span>
                            </div>
                            <div class="process__items-cards-card-content-desc">
                                <span class="font-16" v-html="item.description"></span>
                            </div>
                            <div class="process__items-cards-card-content-button">
                                <!-- <button class="button btn_black_outline_box font-14">{{ $t("button.seeMore") }} <font-awesome-icon icon="fa-solid fa-arrow-right" /></button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1 from '@/assets/images/png/product-research1.png'
import img2 from '@/assets/images/png/product-research2.png'
import img3 from '@/assets/images/png/product-research3.png'
import img4 from '@/assets/images/png/product-research4.png'
import img5 from '@/assets/images/png/product-research5.png'
import img6 from '@/assets/images/png/product-research6.png'
export default {
    data() {
        return {
            cardData: [
                {
                    id: 1,
                    title: this.$t("process.data.title1"),
                    description: this.$t("process.data.description1"),
                    color: '#9929bd',
                    icon: img1,
                    link: '#'
                },
                {
                    id: 2,
                    title: this.$t("process.data.title2"),
                    description: this.$t("process.data.description2"),
                    color: '#fecb3e',
                    icon: img2,
                    link: '#'
                },
                {
                    id: 3,
                    title: this.$t("process.data.title3"),
                    description: this.$t("process.data.description3"),
                    color: '#dbfff5',
                    icon: img3,
                    link: '#'
                },
                {
                    id: 4,
                    title: this.$t("process.data.title4"),
                    description: this.$t("process.data.description4"),
                    color: '#371a94',
                    icon: img4,
                    link: '#'
                },
                {
                    id: 5,
                    title: this.$t("process.data.title5"),
                    description: this.$t("process.data.description5"),
                    color: '#a8c6fe',
                    icon: img5,
                    link: '#'
                },
                {
                    id: 6,
                    title: this.$t("process.data.title6"),
                    description: this.$t("process.data.description6"),
                    color: '#aaaaaa',
                    icon: img6,
                    link: '#'
                },
        
            ]
        }
    }
}
</script>

<style>

</style>