<template>
    <div class="header">
        <div class="header__content container">
            <div class="header__items">
                <div class="header__items-logo">
                    <img src="@/assets/images/png/logo.png" alt="" />
                </div>
                <div class="header__items-nav">
                    <ul class="m_none">
                        <li v-for="(link, idx ) in headerLinks" :key="idx">
                            <button @click="scrollToSection(link.id)" class="button"
                                :class="headerLinks.length === idx + 1 ? 'btn_purple' : 'btn_black_outline'">{{
                                        link.name
                                }}</button>
                        </li>
                        <li>
                            <ul style="position: relative;" class="font-lang">
                                <li ><Dropdown :title="titles2" :links="langLinks"></Dropdown></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Dropdown from '@/components/DropdownMenu.vue'
export default {
    components :{
        Dropdown
    },
    data() {
        const lang = localStorage.getItem('lang') || 'ru';
        return {
            titles: [],
            titles2: '',
            headerLinks: [
                { name: this.$t("navLink.aboutus"), id: 'about' },
                { name: this.$t("navLink.services"), id: "service" },
                { name: this.$t("navLink.howwork"), id: "work" },
                { name: this.$t("navLink.casestudy"), id: "study" },
                { name: this.$t("navLink.letstalk"), id: "talk" },
            ],
            selected: lang,
            langLinks : [
                {
                    name: 'Рус',
                    value: 'ru'
                },
                {
                    name: 'Eng',
                    value: 'en'
                }
            ]
        }
    },
    created(){
       
    },
    mounted() {
        // if (localStorage.getItem('lang')) {
        //     this.$i18n.locale = localStorage.getItem('lang')
        // }
        this.titles = this.langLinks.filter((title) => title.value === this.selected)
        this.titles2 =  this.titles[0].name
            
    },
    methods: {
        scrollToSection(link) {
            this.$emit("scroll", link);
        },
        changeLang(selected) {
            localStorage.setItem('lang', selected)
            window.location.reload()
        }
    }
}
</script>

<style scoped lang="scss">
select,
option {
    border: none;
    color: #303030;
    font-family: "Lato", Helvetica, sans-serif;
    // vertical-align: middle;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 700;

    &:focus-visible {
        outline: none;
    }
}
</style>