<template>
    <div class="studies" id="study">
        <div class="studies__content container">
            <div class="studies__items m_none">
                <div class="studies__items-top">
                    <div class="studies__items-top-title">
                        <h2 class="font-32">{{ $t("studies.title") }}</h2>
                    </div>
                    <div class="studies__items-top-desc">
                        <p class="font-16">{{ $t("studies.description") }}</p>
                    </div>
                </div>
                <div class="studies__items-tab">
                    <div class="studies__items-tab-item">
                        <TabItemVue :tabItems="tabs" :selected="selected" @setTab="selectTab"></TabItemVue>
                    </div>
                    <div class="studies__items-tab-block">
                        <TabBlockVue v-for="(item_0, index) in tabs" :key="index"
                            :isSelected="selected.id === item_0.id">
                            <div class="studies__items-tab-block-items">
                                <div class="studies__items-tab-block-items-phone">
                                    <img class="imgScreen" :src="item_0.image" alt="">
                                    <img class="imgBorder" src="@/assets/images/png/iphoneborder.png" alt="">
                                </div>
                                <div class="studies__items-tab-block-items-download">
                                    <div class="studies__items-tab-block-items-download-title">
                                        <span class="font-24">{{ item_0.title }}</span>
                                    </div>
                                    <div class="studies__items-tab-block-items-download-desc">
                                        <span class="font-16">{{ item_0.description }}</span>
                                    </div>
                                    <div class="studies__items-tab-block-items-download-button">
                                    </div>
                                </div>
                            </div>
                        </TabBlockVue>
                    </div>
                </div>
            </div>
            <div class="studies__items d_none">
                <div class="studies__items-mobile">

                    <div class="studies__items-mobile-items" v-for="(item, index) in tabs" :key="'a' + index">
                        <div class="studies__items-mobile-items-phone">
                            <img class="imgScreen" :src="item.image" alt="">
                            <img class="imgBorder" src="@/assets/images/png/iphoneborder.png" alt="">
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div class="studies__items-mobile-items-download">
                            <div class="studies__items-mobile-items-download-title">
                                <span class="font-24">{{ item.title }}</span>
                            </div>
                            <div class="studies__items-mobile-items-download-desc">
                                <span class="font-16">{{ item.description }}</span>
                            </div>
                            <div class="studies__items-mobile-items-download-button">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1 from '@/assets/images/png/tabicon1.png'
import img2 from '@/assets/images/png/tabicon2.png'
import img3 from '@/assets/images/png/tabicon3.png'
import img4 from '@/assets/images/png/tabicon4.png'
import img5 from '@/assets/images/png/tabicon5.png'
import img6 from '@/assets/images/png/tabicon6.png'
import img7 from '@/assets/images/png/tabicon7.png'
import screenshot1 from '@/assets/images/png/screenshot1.png'
import screenshot2 from '@/assets/images/png/screenshot2.png'
import screenshot3 from '@/assets/images/png/screenshot3.png'
import screenshot4 from '@/assets/images/png/screenshot4.png'
import screenshot5 from '@/assets/images/png/screenshot5.png'
import screenshot6 from '@/assets/images/png/screenshot6.png'
import screenshot7 from '@/assets/images/png/screenshot7.png'
import TabBlockVue from '../tabs/TabBlock.vue'
import TabItemVue from '../tabs/TabItem.vue'

export default {
    components: {
        TabBlockVue,
        TabItemVue
    },
    data() {
        return {
            selected: '',
            tabs: [
                {
                    id: 1,
                    text: 'Nommi',
                    icon: img1,
                    image: screenshot1,
                    title: this.$t("studies.data.title1"),
                    description: this.$t("studies.data.description1"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
                {
                    id: 2,
                    text: 'Alfa Business 2.0',
                    icon: img2,
                    image: screenshot2,
                    title: this.$t("studies.data.title2"),
                    description: this.$t("studies.data.description2"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
                {
                    id: 3,
                    text: 'Free Wi-Fi',
                    icon: img3,
                    image: screenshot3,
                    title: this.$t("studies.data.title3"),
                    description: this.$t("studies.data.description3"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
                // {
                //     id: 4,
                //     text: 'Beeline',
                //     icon: img4,
                //     image: screenshot4,
                //     title: this.$t("studies.data.title4"),
                //     description: this.$t("studies.data.description4"),
                //     appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                //     playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                // },
                {
                    id: 5,
                    text: 'Wanty',
                    icon: img5,
                    image: screenshot5,
                    title: this.$t("studies.data.title5"),
                    description: this.$t("studies.data.description5"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
                {
                    id: 6,
                    text: 'Feels',
                    icon: img6,
                    image: screenshot6,
                    title: this.$t("studies.data.title6"),
                    description: this.$t("studies.data.description6"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
                {
                    id: 7,
                    text: 'Tips App',
                    icon: img7,
                    image: screenshot7,
                    title: this.$t("studies.data.title7"),
                    description: this.$t("studies.data.description7"),
                    appstore: 'https://apps.apple.com/kz/app/nommi/id1428584000',
                    playmarket: 'https://play.google.com/store/apps/details?id=com.nommi&hl=ru&gl=US'
                },
            ],
        }
    },
    mounted() {
        this.selected = this.tabs[0]
    },
    methods: {
        selectTab(tab) {
            this.selected = tab
            console.log(tab)
        },
        watch: {
            selected() {
                //   this.fetchReport();
            }
        }
    }
}
</script>

<style>

</style>