<template>
  <div class="header__item" @mouseleave="isOpen = false">
    <button
      class="header__link font-14 uppercase"
      @mouseover="isOpen = true"
      :class="{ active: isOpen }"
      >{{ title }}</button
    >
    <transition name="fade" appear>
      <nav class="sub-menu" v-if="isOpen">
        <ul class="sub-menu__list">
          <li v-for="(link, i) in links" :key="i">
              <button @click="changeLang(link)" class="sub-menu__link"><span>{{link.name}}</span></button>
          </li>
        </ul>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    title: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods:{
    changeLang(link) {
            localStorage.setItem('lang', link.value)
            window.location.reload()
        }
  }
};
</script>

<style lang="scss">
.header{
  &__item{
   button{
    border: unset;
    background-color: transparent;

   }
  }
  &__link{
    font-family: 'Lato', Helvetica, sans-serif;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 700;
    color: #303030;
    margin: 0 5px;
    
  }
}
.sub-menu {
  // min-width: 255px;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 16px 0 0 0;
  z-index: 5;
  background: #fff;
  &__list {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
    display: flex;
    flex-direction: column;
  
    li:not(:last-child) {
      margin: 0 0 16px 0;
    }
  }
  &__link {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 118.75%;
    // color: #272724;
    color: rgba(32, 32, 32, 0.8);
    transition: color 0.3s ease 0s;
    white-space: nowrap;
    font-family: 'Lato', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 700;
    color: #303030;

    &:hover {
      font-weight: 900;
      color: #3252C6;
      // border: unset!important;

    }
  }
}
.fade-enter-active,
.fade-enter-leave {
  transition: all 0.5s ease-out;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}
</style>
