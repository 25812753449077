<template>
    <div class="about" id="about">
        <div class="about__content container">
            <div class="about__items">
                <div class="about__items-top">
                    <div class="about__items-top-title">
                        <h2 class="font-32">{{ $t('cardData.title') }}</h2>
                    </div>
                    <div class="about__items-top-desc">
                        <p class="font-16">{{ $t('cardData.description') }}</p>
                    </div>
                </div>
                <div class="about__items-cards">
                    <div class="about__items-cards-card" v-for="(item, index) in cardData" :key="index">
                        <div class="about__items-cards-card-title">
                            <img :src="item.img" alt="" />
                            <span class="font-12" v-html="item.title"></span>
                        </div>
                        <div class="about__items-cards-card-desc">
                            <span class="font-16" v-html="item.description"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import card1 from '@/assets/images/svg/cards/card1.svg'
import card2 from '@/assets/images/svg/cards/card2.svg'
import card3 from '@/assets/images/svg/cards/card3.svg'
import card4 from '@/assets/images/svg/cards/card4.svg'
import card5 from '@/assets/images/svg/cards/card5.svg'
export default {
    data() {
        return {
            cardData:[
                {
                    id: 1,
                    img: card1,
                    title: this.$t("cardData.cards.title1"),
                    description: this.$t("cardData.cards.description1")
                },
                {
                    id: 2,
                    img: card2,
                    title: this.$t("cardData.cards.title2"),
                    description: this.$t("cardData.cards.description2")
                },
                {
                    id: 3,
                    img: card3,
                    title: this.$t("cardData.cards.title3"),
                    description: this.$t("cardData.cards.description3")
                },
                {
                    id: 4,
                    img: card4,
                    title: this.$t("cardData.cards.title4"),
                    description: this.$t("cardData.cards.description4")
                },
                {
                    id: 5,
                    img: card5,
                    title: this.$t("cardData.cards.title5"),
                    description: this.$t("cardData.cards.description5")
                },

            ]
        }
    },

}
</script>

<style>

</style>