import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './assets/styles/main.css'
import "swiper/css";
import 'swiper/scss/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import i18n from './i18n';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

const lang = localStorage.getItem('lang') || 'ru';
// axios.defaults.baseURL = 'https://qazgaz-back.test-nomad.kz/api/';
axios.defaults.params = {
    'lang': lang,
  };
library.add(faUserSecret)

createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(VueSweetalert2)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
