<template>
    <div class="development" id="service">
        <div class="development__content container">
            <div class="development__items">
                <div class="development__items-top">
                    <div class="development__items-top-title">
                        <h2 class="font-32">{{ $t("development.title") }}</h2>
                    </div>
                    <div class="development__items-top-desc">
                        <p class="font-16">{{ $t("development.description") }}</p>
                    </div>
                </div>
                <div class="development__items-cards">
                    <div class="development__items-cards-card" v-for="(item, index) in cardData" :key="index">
                        <div :style="`background-color: ` + item.color + `;`"
                            class="development__items-cards-card-line">

                        </div>
                        <div class="development__items-cards-card-content">
                            <div class="development__items-cards-card-content-title">
                                <span class="font-19" v-html="item.title"></span>
                            </div>
                            <div class="development__items-cards-card-content-desc">
                                <span class="font-16" v-html="item.description"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cardData: [
                {
                    id: 1,
                    title: this.$t("development.data.title1"),
                    description: this.$t("development.data.description1"),
                    color: '#9929bd'
                },
                {
                    id: 2,
                    title: this.$t("development.data.title2"),
                    description: this.$t("development.data.description2"),
                    color: '#3a88fe'
                },
                {
                    id: 3,
                    title: this.$t("development.data.title3"),
                    description: this.$t("development.data.description3"),
                    color: '#fffc41'
                }
            ]
        }
    }
}
</script>

<style>

</style>