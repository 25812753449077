<template>
    <div class="feedback" id="talk">
        <!-- <div v-if="errorStr">
    Sorry, but the following error
    occurred: {{errorStr}}
  </div>
  
  <div v-if="gettingLocation">
    <i>Getting your location...</i>
  </div>
        <div v-if="location">
    Your location data is {{ location.coords.latitude }}, {{ location.coords.longitude}}
  </div> -->
        <div class="feedback__content container">
            <div class="feedback__items">
                <div class="feedback__items-block">
                    <div class="feedback__items-block-top">
                        <div class="feedback__items-block-top__small">
                            <div class="feedback__items-block-top-title">
                                <span class="font-32">{{ $t("feedback.title") }}</span>
                            </div>
                            <div class="feedback__items-block-top-desc">
                                <span class="font-16">{{ $t("feedback.desc") }}</span>
                            </div>
                            <form @submit="sendFeedback" onsubmit="return false" action="submit">
                                <label for="input"><span class="red">*</span> {{ $t("feedback.topinput") }}:</label>
                                <div class="feedback__items-block-top-desc-input">
                                    <input type="email" v-model="email" placeholder="your@email.com" />
                                    <button class="button btn_black_outline_red">{{ $t("button.getaquote") }}
                                        <font-awesome-icon icon="fa-solid fa-arrow-right" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="feedback__items-block-bottom">
                        <div class="feedback__items-block-bottom__small">
                            <div class="feedback__items-block-bottom-title">
                                <span class="font-24">{{ $t("feedback.minititle") }}</span>
                            </div>
                            <div class="feedback__items-block-bottom-desc">
                                <span class="font-16">{{ $t("feedback.minidesc") }}</span>
                            </div>
                            <div class="feedback__items-block-bottom-button">
                                <a href="mailto:hello@oitech.io"> <span class="ch1">{{
                                    $t("button.contactusat")
                                }}</span><span class="ch2">hello@oitech.io</span> <font-awesome-icon
                                        icon="fa-solid fa-arrow-right" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    data: () => ({
        email: null,
        location: null,
        gettingLocation: false,
        errorStr: null
    }),
    computed: {
        browser() {
            return navigator.userAgent
        }
    },
    mounted() {
        // this.showPosition()
    },
    methods: {
        sendFeedback() {
            const article = {
                Email: this.email,
                Browser: this.browser,
                Location: 'unset',
                Date: Date()
            };
            axios
                .post("https://sheetdb.io/api/v1/xyno84um597q1", article)
                .then((response) => {
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Спасибо, ваша заявка принята!',
                            text: 'Мы свяжемся с вами в ближайшее время',
                           
                        })

                    } else {
                        console.log(response)
                    }
                })
                .catch((e) => {

                    console.log(e)
                })
        },
        showPosition() {
            if (!("geolocation" in navigator)) {
                this.errorStr = 'Geolocation is not available.';
                return;
            }
            this.gettingLocation = true;
            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                this.gettingLocation = false;
                this.location = pos;
            }, err => {
                this.gettingLocation = false;
                this.errorStr = err.message;
            })

        }
    }

}
</script>

<style>

</style>