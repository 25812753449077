<template>
  <div class="footer">
    <div class="footer__content container">
        <div class="footer__items">
            <div class="footer__items-copyright">
                <span class="font-16">{{ $t("other.all") }}</span>
            </div>
            <div class="footer__items-socials">
                <a target="_blank" href="https://www.linkedin.com/company/oitech-io/"><img src="@/assets/images/svg/linkedin.svg" alt=""></a>
                <a  target="_blank" href="https://www.instagram.com/oitech.io/"><img src="@/assets/images/svg/instagram.svg" alt=""></a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>