<template>
  <HeaderVue @scroll="scrollTo" v-if="$route.name !== 'zebra'" />
  <router-view />
  <FooterVue v-if="$route.name !== 'zebra'" />
</template>
<script>
import HeaderVue from './components/layout/Header.vue';
import FooterVue from './components/layout/Footer.vue';
export default {
  components: {
    HeaderVue,
    FooterVue
  },
  data: () => ({

  }),
  methods: {
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  }
}
</script>
<style lang="scss"></style>
