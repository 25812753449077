<template>
  <div class="tab-items">
    <ul class="tab-list">
      <li v-for="tab in tabItems" :key="tab" class="tab-item">
        <div :class="{ active: tab === selected }" class="tab-link second-title font-20" @click="setTab(tab)">
          <img :src="tab.icon" alt="">
          <p>{{ tab.text }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    tabItems: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: true
    }
  },
  methods: {
    setTab(tab) {
      this.$emit('setTab', tab)
    }
  }
}
</script>

<style scoped lang="scss">
.tab-items {
  position: relative;
}

.tab-list {
  display: flex;
  align-items: center;
  gap: 6px 10px;
  margin: 0;
  padding: 0;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  // overflow: scroll;
}

.tab-link {
  cursor: pointer;
  padding: 12px 20px 10px;
  border-radius: 10px;
  transition: background-color .2s ease;
  color: #202020;
  text-align: center;
  // transition: all 0.3s ease 0s;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-direction: column;
  img {
    max-height: 30px;
  }

  p {
    font-family: 'Lato', Helvetica, sans-serif;
    max-width: 115px;
    line-height: 1.4;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: rgba(48,48,48,0.85);

  }

  &:hover,
  &.active {
    background-color: #DFEAFE;

  }

  &.active {
    cursor: default;
  }
}

@media (max-width: 576px) {
  .tab-list {
    flex-direction: column;
    align-items: flex-start;

  }

  .tab-item {
    margin: 1% 0;
  }
}
</style>