<template>
  <div class="home">
    <HomeMainBanner @scroll="scrollTo" />
    <HomeMainAbout />
    <HomeMainDevelopment />
    <HomeMainProcess />
    <HomeMainStudies />
    <HomeMainFeedback />
  </div>
</template>

<script>
import HomeMainBanner from '@/components/home/MainBanner.vue'
import HomeMainAbout from '@/components/home/MainAbout.vue'
import HomeMainDevelopment from '@/components/home/MainDevelopment.vue'
import HomeMainProcess from '@/components/home/MainProcess.vue'
import HomeMainStudies from '@/components/home/mainStudies.vue'
import HomeMainFeedback from '@/components/home/MainFeedback.vue'

export default {
  name: 'HomeView',
  components: {
    HomeMainBanner,
    HomeMainAbout,
    HomeMainDevelopment,
    HomeMainProcess,
    HomeMainStudies,
    HomeMainFeedback
  },
  data: () => ({

  }),
  methods: {
    scrollTo(link) {
      const offsetTop = document.getElementById(link);
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  }
}
</script>
