<template>
  <div class="main">
    <div class="main__content container">
      <div class="main__items row">
        <div class="main__items-left col">
          <div class="main__items-left-title">
            <h1 class="font-48">{{ $t('firstblock.title') }}</h1>
          </div>
          <div class="main__items-left-desc">
            <p class="font-16">{{ $t('firstblock.description') }}</p>
          </div>
          <div class="main__items-left-button">
            <button class="button btn_purple" @click="scrollToSection('talk')">{{ $t('button.contactus') }}</button>
            <button class="button btn_black_outline" @click="scrollToSection('about')">{{ $t("button.more") }}
              <font-awesome-icon icon="fa-solid fa-arrow-right" /></button>
          </div>
        </div>
        <div class="main__items-right col">
          <div class="main__items-right-image">
            <img src="@/assets/images/svg/rocket.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  }
}
</script>

<style>

</style>